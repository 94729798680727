@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;700&family=Open+Sans:wght@300;400;600;800&family=Encode+Sans+SC:wght@100;300;400;500;600;800&display=swap");
/* START GLOBAL CSS */
/* START GLOBAL CSS */

* {
	margin: 0;
	padding: 0;
}
html {
	width: 100%;
	height: 100%;
	scroll-behavior: smooth;
	overflow-x: hidden;
}
body {
	width: 100%;
	height: 100%;
	font-family: "Comfortaa", cursive;
}
h2 {
	font-size: 2rem;
}
nav {
	color: rgb(255, 255, 255);
	padding-top: 2em;
	width: 100%;
	height: 5em;
	position: absolute;
	top: 0;
}
b {
	color: #aa8bba;
}
button {
	font-family: inherit;
	letter-spacing: 2px;
	font-weight: 700;
	padding: 1.5em 2em;
	background: white;
	border: 1px solid black;
}
a {
	color: inherit;
	text-decoration: none;
}
button:hover {
	cursor: pointer;
	background-color: black;
	color: white;
}
/* END GLOBAL CSS */
/* END GLOBAL CSS */

/* START GENERAL CLASSES AND SELECTORS/PSEUDO SELECTORS ETC */
.white {
	color: white;
}
.black {
	color: black;
}
.center-text {
	text-align: center;
}
.s-padding {
	padding: 1em;
}
::selection {
	background-color: #aa8bba;
	color: black;
	outline: none;
	border: none;
}
:focus {
	border-bottom: 2px solid white;
}
/* END GENERAL CLASSES AND SELECTORS/PSEUDO SELECTORS ETC */

/* START INITIAL WRAPPER */
/* START INITIAL WRAPPER */
#dark-mode-wrapper {
	transition: background-color 800ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
/* START INITIAL WRAPPER */
/* START INITIAL WRAPPER */
/* START HEADER */
/* START HEADER */
#r-nav {
	display: flex;
	justify-content: space-around;
	width: 30vw;
	padding-right: 5em;
	font-size: 0.9rem;
}

#nav-wrapper {
	display: flex;
	justify-content: space-between;
	padding-left: 5em;
	height: 100%;
	align-items: center;
}

#nav-wrapper > *::selection {
	background-color: white;
}

#switch-container {
	z-index: 500;
	transform: rotate(90deg);
	position: fixed;
	display: flex;
	align-items: center;

	left: -2em;
	top: 2.75em;
}
.switch-button {
	box-shadow: rgba(0, 0, 0, 0.3) 1.95px 1.95px 2.6px;
	height: 1.25em;
	width: 1.25em;
	border-radius: 1.25em;
	background: white;
	transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
#switch-text {
	text-align: center;
	white-space: pre-wrap;
	transform: rotate(-90deg);
	font-weight: 700;
	font-size: 0.8rem;
}
#switch {
	cursor: pointer;
	z-index: 5;
	padding: 0.25em;
	display: flex;
	align-items: center;
	width: 4em;
	height: 1.5em;
	border-radius: 1.5em;
	background: gray;
}

.button-toggle > div {
	transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
	transform: translateX(2.75em);
}

/* nav links styling */
.link-line {
	display: inline;
	position: relative;
	overflow: hidden;
}
.link-line:after {
	content: "";
	position: absolute;
	z-index: 0;
	right: 0;
	width: 0;
	bottom: -3px;
	background: white;
	height: 5px;
	transition-property: width;
	transition-duration: 300ms;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.link-line:hover:after,
.link-line:focus:after,
.link-line:active:after {
	left: 0;
	right: auto;
	width: 100%;
}
/* end nav link styling */

/* END HEADER */
/* END HEADER */

/* START HOME PAGE */
/* START HOME PAGE */
.light-bkg {
	transition: background-color 800ms cubic-bezier(0.215, 0.61, 0.355, 1);
	background-color: white;
}
.home-bkg {
	background-color: white;
	background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		url(./bkg.jpg);
}
.dark-bkg {
	transition: background-color 800ms cubic-bezier(0.215, 0.61, 0.355, 1);
	background-color: #131516;
	color: white;
}

#home-wrapper {
	width: 100%;
	height: 80vh;
	background-size: cover;
	background-repeat: no-repeat;
}
.flashy-box {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 15em;
	left: 5em;
	height: 10em;
	width: 50em;
	border: 1px solid white;
	color: white;
}
.flashy-box h1 {
	font-family: "Encode Sans SC", sans-serif;
	font-weight: 300;
	font-size: 3.5rem;
	letter-spacing: 3px;
	padding: 0.5em;
}
#box-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* END HOME PAGE */
/* END HOME PAGE */

/* START ABOUT PAGE */
/* START ABOUT PAGE */

#about {
	padding-top: 2em;
	width: 75%;
	height: 100%;
	display: flex;
	gap: 4em;
	justify-content: space-between;
	align-items: center;
}

#about-wrapper {
	display: flex;
	justify-content: center;
}

.column-text {
	white-space: pre-wrap;
	font-weight: 600;
	color: gray;
	font-size: 0.9rem;
	line-height: 1.8;
}

.column {
	font-family: "Open Sans", sans-serif;
	display: flex;
	flex-direction: column;
	width: 40em;
}
.column h2 {
	font-family: "Encode Sans SC", sans-serif;
	font-weight: 700;
	letter-spacing: 2px;
	padding-bottom: 1.5em;
}

.year-text {
	font-size: 0.9rem;
	font-weight: 600;
	padding: 1em;
	color: gray;
}

/* END ABOUT PAGE */
/* END ABOUT PAGE */

#creations-heading {
	padding: 5em;
	justify-content: space-between;
	display: flex;
	align-items: center;
}
#creations-heading h1 {
	font-family: "Encode Sans SC", sans-serif;
	font-weight: 400;
}

/* START IMAGE GRIDS AND CONTACT GRID */
/* START IMAGE GRIDS AND CONTACT GRID */

#grid-wrapper {
	padding: 0em 5em 5em 5em;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1em;
	grid-template-rows: 3;
}
.grid-item {
	height: 650px;
	max-width: 412px;
	display: flex;
	align-items: flex-end;
	color: white;
	opacity: 0.9;
}
.grid-item h1 {
	font-family: "Encode Sans SC", sans-serif;
	font-weight: 300;
	background-color: rgba(0, 0, 0, 0.6);
	line-height: 2rem;
	width: 100%;
	padding: 0.5em;
	transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.grid-item:hover {
	background-color: rgba(255, 255, 255, 0.5);
	background-blend-mode: overlay;
	transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.grid-item:hover h1 {
	background-color: rgba(0, 0, 0, 0);
	background-blend-mode: overlay;
	color: black;
	letter-spacing: 3px;
	transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
	font-weight: 500;
}
.grid-item a {
	text-transform: uppercase;
	color: inherit;
	text-decoration: none;
}
.item-1 {
	grid-column-start: 1;
	background-image: url("./ryzen.png");
	background-repeat: no-repeat;
}
.item-2 {
	grid-column-start: 2;
	background-image: url("./airquality.png");
	background-repeat: no-repeat;
}
.item-3 {
	grid-column-start: 3;
	background-image: url("./code.png");
	background-repeat: no-repeat;
}
.item-4 {
	grid-column-start: 4;
	background-image: url("./react.png");
	background-repeat: no-repeat;
}
#contact-grid {
	padding-top: 1.25em;
	display: grid;
	grid-template-columns: 5;
	grid-template-rows: 1;
	gap: 3em;
}
.contact-item-1 {
	grid-column-start: 1;
}
.contact-item-2 {
	grid-column-start: 2;
}
.contact-item-3 {
	grid-column-start: 3;
}
.contact-item-4 {
	grid-column-start: 4;
}
.contact-item-5 {
	grid-column-start: 5;
}
.contact-img {
	width: 32px;
}
/* END IMAGE GRIDS AND CONTACT GRID */
/* END IMAGE GRIDS AND CONTACT GRID */

#contact {
	display: flex;
	justify-content: center;
}

.typing-demo {
	width: 21ch;
	animation: typing 2s steps(20), blink 0.5s step-end infinite alternate;
	white-space: pre;
	overflow: hidden;
	border-right: 3px solid;
	margin-right: 20px;
}

@keyframes typing {
	from {
		width: 0;
	}
}

@keyframes blink {
	50% {
		border-color: transparent;
	}
}

#footer {
	width: 100%;
}

@media only screen and (max-width: 980px) {
	.flashy-box {
		position: static;
		width: 25em;
	}
	.column h2 {
		text-align: center;
	}
	#about {
		flex-direction: column;
	}
	#grid-wrapper {
		padding: 0em 5em 5em 5em;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1em;
		grid-template-rows: 2;
	}
	.item-1 {
		grid-column-start: 1;
	}
	.item-2 {
		grid-column-start: 2;
	}
	.item-3 {
		grid-column-start: 1;
		grid-row-start: 2;
	}
	.item-4 {
		grid-column-start: 2;
		grid-row-start: 2;
	}
}

@media only screen and (max-width: 700px) {
	#nav-wrapper {
		flex-direction: column;
		padding-left: 0;
	}
	#r-nav {
		padding-right: 0;
		width: 25em;
	}

	#creations-heading {
		padding: 1em;
	}
	.column {
		width: 100%;
	}
	#grid-wrapper {
		padding: 0;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 1em;
		grid-template-rows: 4;
		justify-items: center;
	}
	.grid-item {
		width: 412px;
	}
	.item-1 {
		grid-column-start: 1;
		grid-row-start: 1;
	}
	.item-2 {
		grid-column-start: 1;
		grid-row-start: 2;
	}
	.item-3 {
		grid-column-start: 1;
		grid-row-start: 3;
	}
	.item-4 {
		grid-column-start: 1;
		grid-row-start: 4;
	}
	#grid-wrapper h1 {
		width: 412px;
	}
}

@media only screen and (max-width: 412px) {
	.flashy-box h1 {
		font-size: 2.5rem;
	}
	.flashy-box {
		height: 10em;
		width: 18em;
	}
	.tyler-img {
		width: 100%;
	}
	.grid-item {
		width: 100%;
	}
	#grid-wrapper h1 {
		width: 100%;
	}
}
